import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import "./Services.css";


function Services(props) {

    console.log(props)
    const data = props.data;
    return (
        <div className="pt-16 md:pt-32 m-auto text-center" style={props.stylez ? props.stylez : { display: "block" }}>
            {data.title && (
                <>
                    <p className="section-name">{data.sectionName}</p>
                    <h2 className="" >
                        {data.title}
                    </h2>
                    <p className="m-auto mt-4" style={{ maxWidth: "500px" }}>{data.servicesDescription}</p>
                </>
            )}
            <div className="py-8 md:py-16 text-left" >
                <div className="m-auto" style={{ maxWidth: "1280px" }}>
                    <div className="flex flex-wrap py-4">
                        {props.data.services.services.nodes.map((service, index) => (
                            <div key={`service-${service.title}`} className="w-full md:w-1/3 flex relative">
                                <Link to={`${service.uri}`} className="m-8 m-1 relative" >
                                    <div>
                                        <GatsbyImage
                                            image={service.servicesAcf.featuredImage.localFile.childImageSharp.gatsbyImageData}
                                            alt={service.servicesAcf.featuredImage.altText}
                                            className="m-auto sc-m-img" />
                                    </div>
                                    <div className="flex items-center pb-8 pt-4">
                                        <div className="w-3/4">
                                            {data.title ?
                                                <h3 className="py-2 services-h4 service-h4">
                                                    {htmlDecode(service.title)}
                                                </h3>
                                                :
                                                <h2 className="py-4 service-h4">
                                                    {htmlDecode(service.title)}
                                                </h2>
                                            }
                                            <div dangerouslySetInnerHTML={{ __html: service.excerpt }} />
                                        </div>
                                    </div>
                                    <div className="main-btn-container">
                                        <Link to={`${service.uri}`} className="main-button-blue mt-4 md:mt-0">
                                            {props.lang === "EL" ? "Διαβάστε Περισσότερα" : "Read More"}
                                        </Link>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
